import React from "react"

import Seo from "../components/seo";
import Layout from "../components/layout";
import Pricing from '../layouts/Pricing'; 
import {graphql, useStaticQuery} from 'gatsby';

const PricingPageFr = () => {

  const data = useStaticQuery(graphql`
    query PricingDatasFr {
      allContentfulForfaits(sort: {fields: price, order: ASC}, filter: {node_locale: {eq: "fr-CA"}}) {
        edges {
          node {
            name
            inclusions
            price
          }
        }
      }
    }
  `)

  return (
    <Layout 
    lang="fr-CA" 
    switchLink='/en/pricing' 
    slider={false} 
    title='à partir de 19$'
    subTitle='Le forfait que vous cherchiez'>
      <Seo 
        title="Prix et tarification d'un système d'affichage dynamique"
        description="Prix et forfaits pour mettre en place la technologie d'affichage numérique dynamique et interactif, bornes interactives, gestion de contenu. Développez votre marketing créatif!"
       />
      <Pricing plans={data.allContentfulForfaits.edges}/>
    </Layout>
  )
}

export default PricingPageFr
